.peppertypeAI_page_hero :global(.title span) {
  background-image: linear-gradient(
    180deg,
    rgba(227, 51, 255, 1) 0%,
    rgba(117, 102, 255, 1)
  );
  background-clip: 'text';

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
