.navDropdownContent {
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.navDropdownContent[data-motion='from-start'] {
  animation-name: enterFromLeft;
}
.navDropdownContent[data-motion='from-end'] {
  animation-name: enterFromRight;
}
.navDropdownContent[data-motion='to-start'] {
  animation-name: exitToLeft;
  display: none;
}
.navDropdownContent[data-motion='to-end'] {
  animation-name: exitToRight;
  display: none;
}

.navViewport {
  height: var(--radix-navigation-menu-viewport-height);
  transform-origin: top center;
  transition: width 300ms ease, height, 300ms ease;
  overflow: hidden;
}

.navViewport[data-state='open'] {
  animation: enterIntoNav 500ms ease;
}
.navViewport[data-state='closed'] {
  animation: exitFromNav 200ms ease;
}

.fadeIn {
  animation: fadeIn 1000ms ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.card {
  border-color: transparent;
}

.card:hover {
  border-color: var(--dynamic-color);
}

/* keyframes */
@keyframes enterIntoNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes exitFromNav {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes exitToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-25px);
    opacity: 0;
  }
}

@keyframes exitToRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(25px);
    opacity: 0;
  }
}
