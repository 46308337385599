.accordion :global(.accordion__header) {
  display: flex;
  justify-content: space-between;
}

.accordion :global(.accordion__icon) {
  justify-self: flex-end;
}

.closeIconClass rect {
  fill: #1600e5;
}

.closeIconClass path {
  fill: #ffffff;
}
